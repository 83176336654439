.container {
  display: flex;
  /* gap: 2rem; */
}
.container > div {
  margin: 0px 10px;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.title,
.sc,
.description {
  margin-bottom: 1rem;
}

.sc {
  font-size: 0.9rem;
  color: maroon;
}

.free {
  color: green;
}

.price {
  margin-bottom: -10px;
}

.imageContainer {
  flex: 1;
  min-width: 400px;
}
.imageContainer img {
  width: 100%;
  border-radius: 20px;
}
.title {
  font-size: 2.5rem;
  font-weight: bold;
}
.price {
  font-size: 2rem;
  font-weight: 300;
}
.description {
  font-size: 1.5rem;
  font-weight: 500;
}
.description ul {
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 3rem;
}

.btnContainer {
  display: flex;
  /* gap: 1rem; */
}

.btnContainer > button {
  margin: 10px;
}

.btnAddCart {
  width: 15rem;
  height: 3rem;
  font-size: 1rem !important;
  font-weight: bold !important;
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
    gap: 0;
  }
  .title,
  .description {
    margin-bottom: 0.5rem;
  }
  .title {
    margin-top: 0.5rem;
    font-size: 1.3rem;
  }

  .description {
    font-size: 1.2rem;
  }

  .description ul {
    font-size: 1rem;
  }

  .imageContainer {
    min-width: 300px;
  }

  .btnContainer {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 1rem;
  }
  .btnContainer div {
    display: flex;
    justify-content: center;
  }
}
