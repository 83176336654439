.container {
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.b_right {
  display: flex;
}

.b_right div {
  margin-left: 1rem;
}

.b_center svg {
  margin: 0px 0.5rem;
}

.link {
  text-decoration: none;
  color: #242424;
  transition: all 0.2s ease;
}
.link:hover {
  color: #000000;
}

@media (max-width: 600px) {
  .container {
    height: 140px;
  }
  .bottom {
    flex-direction: column;
  }
  .b_center {
    margin-top: 10px;
  }
}
