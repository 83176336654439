.totalPrice {
  font-size: 1.4rem;
  font-weight: 500;
  color: #003858;
}
.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.btnCheckout {
  padding: 0.5rem 1rem;
}

.cartIcon {
  font-size: 8rem !important;
}

.noContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.noTitle {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}

.noContent {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 2rem;
}

.container {
  display: flex;
  /* gap: 1rem; */
  margin-top: 1rem;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .container {
    justify-content: center;
  }
}
