.container {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.25s ease;
  border-radius: 0.5rem;
  width: 324px;
  margin: 10px;
}

.link {
  text-decoration: none;
  color: black;
  margin-right: 1rem;
}

.content {
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center;
  align-items: center; */

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img {
  width: 5rem;
  height: 5rem;
  border-radius: 1rem;
  border: 1px solid #ccc;
  transition: all 0.5s ease;
}

.title {
  font-size: 1.2rem;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price {
  font-size: 1.2rem;
  font-weight: 500;
}
