.newContainer {
  display: flex;
  justify-content: center;
}

.container {
  height: calc(100vh - 190px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 1rem; */
  max-width: 300px;
}

.container > button {
  margin-bottom: 16px;
}

@media (max-width: 600px) {
  .container {
    height: calc(100vh - 250px);
  }
}
