.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.qty {
  font-size: 1.5rem;
  text-align: center;
  min-width: 4rem;
}
