*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hrStyle {
  border-top: 1px solid #dcdcdc;
  margin-bottom: 1rem;
}

.hrStyleDesktop {
  display: none;
}

.headingTitle {
  font-size: 1.4rem;
}

.failed,
.active,
.completed,
.cancelled {
  position: relative;
}
.failed::before,
.active::before,
.completed::before,
.cancelled::before {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 10px;
  top: 5.5px;
  left: -15px;
  border-radius: 50%;
}

.failed::before {
  background-color: red;
}

.active::before {
  background-color: green;
}

.completed::before {
  background-color: darkblue;
}

.cancelled::before {
  background-color: #9c27b0;
}

.delivered {
  color: green;
}

.inprogress {
  color: red;
}

@media (max-width: 600px) {
  .hrStyleDesktop {
    margin: 0.5rem 0rem;
    display: block;
  }
}

html {
  height: 100%;
}

body {
  margin: 0;
  position: relative;
  min-height: 100%;
  padding-bottom: 4rem;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
  background-image: url("/public/wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
}

@media (max-width: 600px) {
  body {
    padding-bottom: 10rem;
  }
}
