.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error {
  font-size: 1.5rem;
  margin-top: 2rem;
  color: maroon;
  text-align: center;
}

@media (max-width: 600px) {
  .error {
    font-size: 1.2rem;
  }
}
