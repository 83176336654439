.nav {
  background-color: rgba(255, 255, 255, 0.95);
  height: 4.5rem;
  position: sticky;
  top: 0;
  box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.9);
  z-index: 3;
}

.navContainer {
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.brand {
  font-size: 1.6rem;
  color: black;
  text-decoration: none;
}
.icon {
  cursor: pointer;
}

.menu_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_container svg {
  margin-left: 1rem;
  height: 32px;
  width: 32px;
}

@media (max-width: 600px) {
  .nav {
    height: 4rem;
  }
  .brand {
    font-size: 1.3rem;
  }
  .menu_container svg {
    margin-left: 0.7rem;
    height: 28px;
    width: 28px;
  }
}
