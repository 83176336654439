.link {
  text-decoration: none;
  color: black;
  margin: 0px 16px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  min-width: 300px;
  margin-bottom: 1.5rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.25s ease;
}
.cardContainer:hover {
  transform: translateY(-0.5rem);
}
.cardContainer:hover .img {
  transform: scale(1.1);
}

.card {
  width: 100%;
  height: 300px;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  position: relative;
}

.title {
  width: 100%;
  font-weight: 600;
  text-align: center;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.img {
  width: 100%;
  /* object-fit: cover;
  height: 100%; */
  transition: all 0.5s ease;
}

.img:hover {
  transform: scale(1.1);
}

.price {
  position: absolute;
  background-color: white;
  top: 0.8rem;
  right: 0.8rem;
  padding: 0.6rem 1.5rem;
  border-radius: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border: 1px solid #cccccc;
  z-index: 1;
}

.bottom {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}

@media (max-width: 600px) {
  .cardContainer {
    margin-bottom: 0.5rem;
    width: 20rem;
  }
  .link {
    margin: 0px 0px 16px 0px;
  }
}
